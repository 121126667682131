<template>
  <div class="create-faq">
    <DocumentAppBar />
    <div class="ma-4">
      <DocumentForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Hub FAQ
 * ==================================================================================
 **/

import DocumentAppBar from '@/views/Home/Hub/Documents/components/DocumentAppBar'
import DocumentForm from '@/views/Home/Hub/Documents/components/DocumentForm'

export default {
  components: {
    DocumentAppBar,
    DocumentForm,
  },
}
</script>
<style lang="scss">
.create-faq {
  //
}
</style>
